.loading-overlay {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.overlay-inner {
  position: relative;
  min-width: 300px;
  border-radius: 6px;
  background-color: hsla(0, 0%, 56.5%, 0.9);
}
.loading-overlay {
  visibility: hidden;
}
.loading-overlay.show {
  visibility: visible
}
.overlay-text {
  margin-top: 60px;
  margin-bottom: 12px;
  color: #f0f0f0;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}
.loading-spinner {
  position: absolute;
  left: 0%;
  top: -20%;
  right: 0%;
  bottom: auto;
  margin-right: auto;
  margin-left: auto;
}
.loading-spinner,
.loading-spinner:after,
.loading-spinner:before {
  border-radius: 50%;
  width: 2em;
  height: 2em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1s ease-in-out infinite;
  animation: load7 1s ease-in-out infinite;
}
.loading-spinner:after,
.loading-spinner:before {
  content: "";
  position: absolute
}
.loading-spinner:before {
  left: -3.5em;
  -webkit-animation-delay: -.4s;
  animation-delay: -.4s
}
.loading-spinner {
  -webkit-animation-delay: -.2s;
  animation-delay: -.2s
}
.loading-spinner:after {
  left: 3.5em
}
@-webkit-keyframes load7 {
  0%,
  80%,
  to {
    box-shadow: 0 2.5em 0 -1.3em
  }
  40% {
    box-shadow: 0 2.5em 0 0
  }
}
@keyframes load7 {
  0%,
  80%,
  to {
    box-shadow: 0 2.5em 0 -1.3em
  }
  40% {
    box-shadow: 0 2.5em 0 0
  }
}
