.admin-content {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.search-empty {
  font-style: italic;
}
.forgot-email, .forgot-date {
  flex: 1 1 0;
}
.icon.forgot-resend {
  height: 18px;
  padding: 0px 8px 0px 0px;
}
.icon.forgot-trash {
  height: 19px;
  padding: 0px 8px 0px 4px;
}
