.all-classes .list-elem:hover {
  background-color: transparent;
}
.add-class-group-chooser {
  margin-bottom: 12px;
}

.all-class-column {
  grid-area: allclasses;
}
.teacher-overview {
  grid-area: groupinfo;
}
.teacher-classes {
  grid-area: myclasses;
}
.teacher-stats {
  grid-area: stats;
}

.teacher-container {
  grid-template-columns: 1fr 3fr;
  grid-template-rows: auto 1fr 2fr;
  grid-template-areas:
    "allclasses groupinfo"
    "allclasses myclasses"
    "allclasses stats";
}

@media only screen and (max-width: 700px) {
  .teacher-container {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-template-areas:
      "groupinfo"
      "allclasses"
      "myclasses"
      "stats";
    gap: 12px;
  }
}
@media only screen and (min-width: 700px) {
  .teacher-container {
    display: grid;
    height: 100%;
    column-gap: 12px;
    row-gap: 12px;
  }
}
