@use '../_styles' as *;

.group-action {
  cursor: pointer;
  box-sizing: border-box;
  width: max-content;
  padding: 6px 12px;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  border-color: rgba(0, 0, 0, 0.5);
}
.group-action .icon {
  height: 22px;
  vertical-align: middle;
}
.group-action-label {
  vertical-align: middle;
  font-weight: $body-weight;
}
