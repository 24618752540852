.grow-down-icon-box {
  display: flex;
  justify-content: space-evenly;
}
.grow-down-icon-box .icon {
  display: block; /* otherwise any enclosing div will pad height, since SVG is a native inline element */
  height: 24px;
  margin-bottom: 6px;
  margin-top: 10px;
}
.grow-down-icon-box.grow-down-icon-transition-enter .icon {
  height: 0px
}
.grow-down-icon-box.grow-down-icon-transition-enter-active .icon {
  height: 24px;
  transition: all 200ms;
}
.grow-down-icon-box.grow-down-icon-transition-exit .icon {
  height: 24px;
}
.grow-down-icon-box.grow-down-icon-transition-exit-active .icon {
  height: 0px;
  transition: all 200ms;
}
.grow-down-icon-transition-enter {
  opacity: 0;
  height: 0px;
}
.grow-down-icon-transition-enter-active {
  opacity: 1;
  height: 40px;
  transition: all 200ms;
}
.grow-down-icon-transition-exit {
  opacity: 1;
  height: 40px;
}
.grow-down-icon-transition-exit-active {
  opacity: 0;
  height: 0px;
  transition: all 200ms;
}
