.debugView {
  /* width: 60%; */
  height: 60%;
  position: absolute;
  padding: 5px;
  top: 65px;
  right: 65px;
  left: 65px;
  bottom: 65px;
  border-radius: 5px;
  background-color: rgba(0,0,0,0.75);
  color: rgb(160, 158, 158);
  z-index: 2000;
  overflow: scroll;
}
div.code {
  font-family: monospace;
  white-space: pre;
}
.button.play.debug {
  position: sticky;
  float: right;
  right: 20px;
  top: 16px;
}
.button.play.debug .icon {
  color: white;
}
