.lrchooser-description, .lrchooser-details {
  display: flex;
  justify-content: center;
  opacity: 75%;
}
.lrchooser-description {
  padding-top: 24px;
  margin-bottom: 12px;
  font-size: 22px;
  user-select: none;
}
.lrchooser-details {
  margin-top: 12px;
  padding-bottom: 24px;
  user-select: none;
}
.left-arrow-icon, .right-arrow-icon {
  width: 28px;
  margin-left: 10px;
  margin-right: 10px;
  color: rgb(52, 209, 88);
}
.left-arrow-icon.disabled, .right-arrow-icon.disabled {
  color: rgb(255,255,255);
  opacity: 50%;
}
.lrchooser-choice-row {
  display: flex;
  align-items: center;
  justify-content: center;
}
.lrchooser-selected {
  font-size: 24px;
  width: 150px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  user-select: none;
}
