.tooltip-container {
  position: relative;
  /* height: 100%; */
}
.tooltip {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity 400ms ease, visibility 400ms;
  transition: opacity 400ms ease, visibility 400ms;
  white-space: nowrap;
  user-select: none;
  padding: 3px 6px;
  border: 1px solid #888;
  border-radius: 6px;
  background: white;
}
.tooltip.visible {
  visibility: visible;
  opacity: 1
}
