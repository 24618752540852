.groups-column {
  grid-area: subgroups;

  .list {
    flex-grow: 1;
    min-height: 24px;
  }
}
.group-manager-overview {
  grid-area: groupinfo;
}
.managers-column {
  grid-area: managers;
}
.learners-column {
  grid-area: learners;
}
.group-container {
  grid-template-columns: 1fr 2fr;
  grid-template-rows: auto 1fr 1fr;
  grid-template-areas:
    "subgroups groupinfo"
    "subgroups managers"
    "subgroups learners";
}

@media only screen and (max-width: 700px) {
  .group-container {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-template-areas:
      "groupinfo"
      "subgroups"
      "managers"
      "learners";
    gap: 12px;
  }
}
@media only screen and (min-width: 700px) {
  .group-container {
    display: grid;
    box-sizing: border-box;
    height: 100%;
    /* justify-items: stretch; */
    column-gap: 12px;
    row-gap: 12px;
  }
}
