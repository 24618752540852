.modal2-backdrop {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}
.modal2-content {
  position: relative;
  max-width: 80%;
  min-height: 50px;
  /* width: 50%; */
  min-width: 300px;
  padding: 20px;
  flex: 0 0 auto;
  border-radius: 6px;
  background-color: #f5f5f5;
  box-shadow: 4px 4px 5px 3px #4b4b4b;
}

.modal2-enter {
  opacity: 0;
}
.modal2-enter .modal2-content {
  transform: scale(0.9);
}
.modal2-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}
.modal2-enter-active .modal2-content {
  transform: translateX(0);
  transition: transform 300ms;
}
.modal2-exit {
  opacity: 1;
}
.modal2-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}
.modal2-exit-active .modal2-content {
  transform: scale(0.9);
  transition: transform 300ms;
}
