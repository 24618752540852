.add-schools-dialog-title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 8px;
}
.add-class-group-chooser .description {
  margin-bottom: 8px;
}
.schools-add-class-divider {
  width: 90%;
  margin: 28px auto;
}
.play-as-class-setting {
  margin-top: 12px;
}
.play-as-class-setting .checkbox-box {
  width: 20px;
  margin-right: 8px;
}
.play-as-class-setting.description {
  margin-top: 0px;
  font-style: italic;
  font-weight: normal;
  opacity: 65%;
}
