/*
.progress-widget-container {
  position: absolute;
  right: 24px;
  top: 8px;
  z-index: 1500;
  background-color: rgba(0,0,0,.75);
  font-size: 17px;
  border-radius: 4px;
} */
.progress-widget {
  display: flex;
  align-items: center;
  gap: 4px;
  /* color: #f6f6f6; */
  font-variant-numeric: tabular-nums;
  margin: 4px 12px;
}
.progress-widget .icon {
  height: 15px;
}
