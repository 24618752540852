.popnfade-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  max-width: 80%;
  min-height: 25px;
  /* width: 50%; */
  min-width: 100px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-radius: 6px;
  border-color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  box-shadow: 4px 4px 5px 3px #4b4b4b;
  z-index: 999999;
}

.popnfade-enter {
  opacity: 0;
}
.popnfade-enter .popnfade-content {
  transform: scale(0.9);
}
.popnfade-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}
.popnfade-enter-active .popnfade-content {
  transform: translateX(0);
  transition: transform 300ms;
}
.popnfade-exit {
  opacity: 1;
}
.popnfade-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}
.popnfade-exit-active .popnfade-content {
  transform: scale(0.9);
  transition: transform 300ms;
}
