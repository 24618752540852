@use '../_styles' as *;

.labelled-box {
  /* this really doesn't belong here ... */
  transition: all 300ms;
}
.labelled-box.dragndrop-hilight {
  /* this should go in Content.css */
  border-color: $disabled-hilight-color;
  box-shadow: inset 0 0 2px 0px $hilight-color, 0 0 2px 0px $hilight-color;
}
.class-box.outlined {
  position: relative;
  box-sizing: border-box;
  padding: 6px 10px;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  border-color: rgba(0, 0, 0, 0.25);
  min-width: 150px;
}
.class-box .close-button-upper-right {
  margin-top: 1px;
  margin-right: 2px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 500ms ease;
}
.class-box:hover .close-button-upper-right {
  opacity: 1;
  visibility: visible;
}
.class-list .list-body {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.class-list .list-elem {
  padding-bottom: 0px;
  padding-top: 0px;
}
.class-detail-box {
  display: flex;
  justify-content: space-evenly;
  height: 24px;
  margin-top: 6px;
  margin-bottom: 2px;
}
.class-detail-box .trash-icon, .class-detail-box .class-info, .class-detail-box .class-play {
  height: 100%;
  width: auto;
}

@media only screen and (min-width: 700px) {
  .class-list-box .center-notice-container {
    height: auto;
  }
  .class-list-box .center-notice {
    font-size: 16px;
    width: auto;
  }
}
