@use '../_styles' as *;

/*
.group-manager-overview {
  box-sizing: border-box;
  padding: 10px;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  border-color: rgba(0, 0, 0, 0.25);
}
.group-manager-overview-banner {
  font-size: $banner-font-size;
  font-weight: $banner-weight;
  margin-bottom: 12px;
} */
.path-segment {
  cursor: pointer;
}
.path-segment:hover {
  transition: color 300ms;
}
.path-segment.dragndrop-hilight {
  color: white;
  background-color: rgba(10, 2, 2, 0.5);
  border-radius: 4px;
}
.group-actions {
  margin-top: 12px;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}
