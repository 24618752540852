@use './_styles' as *;

.content {
  height: 100vh;
  overflow: auto;
  background-color: #f8f8f8;
}

.content.spaced {
  position: relative;
  margin-top: 12px;
}

.automagic {
  .icon {
    color: $hilight-color;
  }
  .disabled .icon {
    color: $disabled-hilight-color;
  }
  .button.inset-button.submit-button {
    color: white;
    background-color: $hilight-color;
  }
  .button.tab-button.selected {
    background-color: $hilight-color;
  }
  .path-segment:hover {
    color: $hilight-color
  }
  textarea {
    font-size: 16px;
  }
  .list-elem {
    font-weight: $body-weight;
  }
  .box-header-label {
    font-size: $header-label-font-size;
    font-weight: $header-label-weight;
  }
}

.description {
  font-weight: 500;
}
