@use './_styles' as *;

.ssi-logo-container {
}
.ssi-logo {
  color: $hilight-color;
  width: 286px;
}
.ssi-catch-phrase {
  font-size: 14px;
  opacity: 75%;
}
