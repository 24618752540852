@use '../_styles' as *;

.asr-signup {
  /* margin: 24px 0px;
  font-size: 20px;
  color: white; */
  text-align: center;
  margin-top: 20px;
  max-width: 600px;
}
.asr-signup .welcome-top {
  font-weight: 500;
}
.asr-signup .welcome-body {
  font-size: 18px;
}
.asr-signup .inline-button {
  margin: 8px;
  width: max-content;
  display: flex;
  align-items: center;
  color: $hilight-color;
  background-color: white;
  font-weight: 800;
}
.asr-signup .inline-button.disabled {
  color: $disabled-hilight-color;
  background-color: white;
  opacity: 1;
}
.asr-signup .inline-button div {
  white-space: nowrap;
}
