@use '../_styles' as *;

.user-settings {
  position: relative;
  margin: 24px 0px;
}
.user-content {
  position: relative;
  margin: 16px 24px;
}
.user-container {
  width: 600px;
  max-width: 90%;
}
.user-label {
  margin-top: 8px;
  margin-bottom: 4px;
  font-weight: 700;
}
.form-field {
  line-height: normal;
  width: calc(100% - 16px);
  padding: 6px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0,0,0,0.25);
}
.below {
  margin-top: 16px;
}
.back-button-row {
  color: rgb(53, 199, 89);
}
.user-settings-box.below {
  margin-top: 20px;
}
.logout-row {
  margin-bottom: 12px;
}
.logout-row .button {
  float: right;
}
.button.logout {
  color: $hilight-color;
}
