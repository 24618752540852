.wbl-page {
  height: calc(100% - 32px);
  margin: 16px 24px;
}
.wbl-managers {
  grid-area: managers;
}
.wbl-overview {
  grid-area: groupinfo;
}
.wbl-learners {
  grid-area: learners;
}
.wbl-container {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "managers groupinfo"
    "managers learners";
}

.invite-email-list {
  margin-left: 16px;
}
.confirm-dialog {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

@media only screen and (max-width: 700px) {
  .wbl-container {
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-template-areas:
      "groupinfo"
      "managers"
      "learners";
    gap: 12px;
  }
}
@media only screen and (min-width: 700px) {
  .wbl-container {
    box-sizing: border-box;
    height: 100%;
    column-gap: 12px;
    row-gap: 12px;
  }
}
