.one-line-description {
  margin-bottom: 8px;
}
.one-line-form-box {
  align-items: center;
  display: flex;
  gap: 8px;
}
.one-line-form-box .inline-button {
  width: 30px;
  height: 30px;
}
.one-line-field-form {
  flex: 1;
  position: relative;
}
.one-line-field-form > .input-decoration {
  position: absolute;
  top: 7px;
  left: 8px;
}
.input-decoration > .search-icon {
  height: 18px;
  color: black;
}
.one-line-field-form > input {
  font-size: 16px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  box-sizing: border-box;
  padding: 6px;
  width: 100%;
  height: 33px; /* Need a better idea here, problem is the border-box forces the border inside */
}
.input-decoration + input {
  padding: 6px 6px 6px 32px;
}
