.demo-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}
.demo-box {
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
}
.demo-row {
  display: flex;
  align-items: center;
  gap: 8px;
}
.iu-dropdown {
  min-width: 210px;
}
.polymorph-title {
  font-size: 18px;
  font-weight: 500;
}
