@use '../_styles' as *;

.admin-container {
  /* padding-bottom: $bottom-content-margin; until we can sort this out properly */
}

@media only screen and (max-width: 700px) {
  .person-list-box {
    min-height: 44px;
  }
}
@media only screen and (min-width: 700px) {
  .person-list-box {
    min-height: 100px;
  }
}
