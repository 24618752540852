.center-notice-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.center-notice {
  font-style: italic;
  background: white;
  border-style: solid;
  border-width: 1px;
  border-radius: 6px;
  border-color: rgba(0,0,0,0.25);
  padding: 10px;
}

@media only screen and (max-width: 700px) {
}
@media only screen and (min-width: 700px) {
  .center-notice-container {
    height: 100%;
  }
  .center-notice {
    width: 65%;
    font-size: 18px;
  }
}
