@use '../_styles' as *;

.circular-progress {
  transform: rotate(-90deg);
}

.circular-progress-background {
  fill: none;
  stroke: #eee;
}

.circular-progress-foreground {
  fill: none;
  stroke: $hilight-color;
  stroke-linecap: round;
  /* transition: stroke-dashoffset 0.5s ease 0s; */
}
