@use '../_styles' as *;

.toggle {
  display: inline-block;
  width: 72px;
  height: 40px;
  cursor: pointer;
}
.toggle-rel {
  position: relative;
  width: 100%;
  height: 100%;
}
.toggle-slot {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  z-index: 1;
}
.toggle-bob {
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0px;
  z-index: 2;
  opacity: 0.6;
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
}
.toggle.on .toggle-bob {
  opacity: 1;
  color: $hilight-color;
  right: -24px;
}
