.students {
  grid-area: students
}
.class-overview {
  grid-area: classinfo;
}
.class-stats {
  grid-area: stats;
}

.class-container {
  grid-template-columns: 1fr 2fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "students classinfo"
    "students stats";
}
.class-overview > .manager-overview-banner:first-child {
  opacity: 0.5;
}

.students .grow-down-icon-box {
  display: block;
}
.students .grow-down-icon-box .student-email {
  flex-grow: 1;
  font-weight: 300;
  font-style: oblique;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 6px;
}
.students .student-icon-box, .list-elem.deemphasized .grow-down-icon-box {
  display: flex;
  justify-content: space-evenly;
}
.grow-down-icon-box.grow-down-icon-transition-enter .student-email {
  opacity: 0;
}
.grow-down-icon-box.grow-down-icon-transition-enter-active .student-email {
  opacity: 1;
  transition: all 200ms;
}
.grow-down-icon-box.grow-down-icon-transition-exit .student-email {
  opacity: 1;
}
.grow-down-icon-box.grow-down-icon-transition-exit-active .student-email {
  opacity: 0;
  transition: all 200ms;
}
.students .grow-down-icon-transition-enter {
  opacity: 0;
  height: 0px;
}
.students .grow-down-icon-transition-enter-active {
  opacity: 1;
  height: 66px;
  transition: all 200ms;
}
.students .grow-down-icon-transition-exit {
  opacity: 1;
  height: 66px;
}
.students .deemphasized .grow-down-icon-transition-enter-active {
  opacity: 1;
  height: 41px;
  transition: all 200ms;
}
.students .deemphasized .grow-down-icon-transition-exit {
  opacity: 1;
  height: 41px;
}
.students .grow-down-icon-transition-exit-active {
  opacity: 0;
  height: 0px;
  transition: all 200ms;
}
.students .deemphasized .grow-down-icon-transition-exit-active {
  opacity: 0;
  height: 0px;
  transition: all 200ms;
}

@media only screen and (max-width: 600px) {
  .class-container {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-template-areas:
      "classinfo"
      "students"
      "stats";
    gap: 12px;
  }
}
@media only screen and (min-width: 600px) {
  .class-container {
    display: grid;
    height: 100%;
    column-gap: 12px;
    row-gap: 12px;
  }
}
