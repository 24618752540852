.onboard-video-container {
  display: flex;
  height: 100%;
  background-color: #222;
}
.onboard-video-box {
  margin: auto;
}
.onboard-video {
  width: 400px;
}
@media screen and (max-width: 400px) {
  .onboard-video-container {
    display: block;
  }
  .onboard-video {
    width: 100%;
  }
}
.onboard-banner {
  color: white;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
}
.onboard-instructions {
  color: white;
  font-size: 18px;
  font-stye: italic;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px;
}
.video-overlay-rel {
  position: relative;
}
.video-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 900ms ease;
}
.video-overlay.faded {
  opacity: 0;
}
.play-icon {
  width: 40px;
  z-index: 5000;
}
.onboard-letsgo {
  margin-top: 20px;
}
