.list-item-row {
  display: flex;
  align-items: center;
  gap: 6px;
}
.drops-list .list-elem {
  transition: all 200ms;
  overflow: hidden;
}
.list-elem.list-item-selected {
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  border-color: rgba(0,0,0,0.25);
  padding: 6px;
}
