.text-box {
  display: flex;
  margin: 0px 12px;
  /*
  border: 1px solid rgba(255,255,255,35%);
  border-radius: 6px; */
  overflow-y: auto;
}
/*
@media screen and (orientation:landscape) {
    .text-box {
        height: 260px;
    }
} */
.text {
  margin: auto;
  width: 100%;
  padding: 12px 12px;
  /* color: white; */
  color: #222;
  text-align: center;
  font-size: 16pt;
  opacity: 0;
  transition: opacity 300ms ease;
}
span.src {
  font-style: italic;
  font-weight: 700;
}
span.tgt {
  /* color: rgb(114, 243, 137); */
  font-weight: 700;
}
