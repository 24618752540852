.displayNone {
    display: none;
}

@media (prefers-color-scheme: light) {
    .canvasPresenter {
        background-color: cadetblue;
    }
    .canvasCoHost {
        background-color: cadetblue;
    }
}

@media (prefers-color-scheme: dark) {
    .canvasPresenter {
        background-color: #222222;
    }
    .canvasCoHost {
        background-color: #3d3d3d;
    }
}
