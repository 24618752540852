.all-class-column {
  grid-area: allclasses;
}
.head-of-welsh-overview {
  grid-area: groupinfo;
}
.head-of-welsh-classes {
  grid-area: myclasses;
}
.managers-column {
  grid-area: managers;
}

.head-of-welsh-container {
  grid-template-columns: 1fr 3fr;
  grid-template-rows: auto auto 1fr;
  grid-template-areas:
    "allclasses groupinfo"
    "allclasses myclasses"
    "allclasses managers";
}

.head-of-welsh-stats {
  height: 22px;
  float: right;
}

@media only screen and (max-width: 700px) {
  .head-of-welsh-container {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-template-areas:
      "groupinfo"
      "allclasses"
      "myclasses"
      "managers";
    gap: 12px;
  }
}
@media only screen and (min-width: 700px) {
  .head-of-welsh-container {
    display: grid;
    box-sizing: border-box;
    height: 100%;
    column-gap: 12px;
    row-gap: 12px;
  }
}
