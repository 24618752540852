.group-list-row {
  display: flex;
  gap: 6px;
}
.group-list-name {
  flex-grow: 1;
}
.group-list-controls {
  opacity: 0;
  visibility: hidden;
  transition: opacity 400ms ease;
  padding-right: 4px;
}
.list-elem:hover .group-list-controls {
  opacity: 1;
  visibility: visible;
}
.group-list-controls {
  display: flex;
  align-items: center;
  gap: 5px;
}
.group-list-controls .icon {
  height: 22px;
  vertical-align: middle;
}
