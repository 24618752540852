@use '../_styles' as *;

.ssi-logo {
  cursor: pointer;
}
.announcement-title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 8px;
}
.announcement-body {
  margin-bottom: 16px;
}
.learn-container {
  height: 100%;
  /* max-width: 800px;
  margin: auto; */
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto 1fr auto auto;
  grid-template-areas:
    "status"
    "combo"
    "usercontrol"
    "controls";
  gap: 20px;
}
.status-row {
  grid-area: status;
  display: flex;
  margin-top: 12px;

  .ssi-logo {
    margin-left: $learn-island-offset;
    width: 72px;
    height: 36px;
  }
}
.learner-info {
  flex: 1;
  margin-right: $learn-island-offset;
  display: flex;
  justify-content: right;
  align-items: center;
}
.current-learner {
  padding-right: 12px;
  border-right: 1px solid #222;
}
.status-indicator {
  position: absolute;
  right: 20px;
  top: 20px;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  /* z-index: 2000; */
}
.status-indicator.ready {
    background-color: green;
}
.status-indicator.waiting {
    background-color: red;
}
.combo-container {
  grid-area: combo;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 460px;
  flex: 1;
}
.text-mode-container, .video-container {
  @include shadow;
  @include rounded;
  @include learn-island-inset;
  flex: 1;
  height: 0;
  /* margin: 12px 40px 12px; */
}
.text-mode-container {
  min-height: 150px;
}
.video-container {
  position: relative;

  canvas {
    width: 100%;
    height: 100%;
  }

  .text-container {
    position: absolute;
    bottom: 0px;
    width: 100%;
    text-align: center;
    background: rgba(0,0,0,80%);
    border-bottom-left-radius: $large-box-radius;
    border-bottom-right-radius: $large-box-radius;
    transition: opacity 300ms ease;

    .text {
      opacity: 1;
      color: #eee;
      transition: opacity initial;
    }
  }
}
.canvasPresenter {
  border-radius: $large-box-radius;
}
.text-mode-container {
  position: relative;
  height: 100%;

  .inline-video-container {
    height: 100%;
    margin-top: 0px;
    border-radius: $large-box-radius;
  }
  .inline-video-container.video-playing {
    background: #000;
  }
  .text-box {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
  }
}
.text-mode-container div {
  grid-row: 1;
  grid-column: 1;
}
.transport-controls {
  @include shadow;
  @include rounded;
  @include learn-island-inset;
  padding: 20px 0px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}
.transport-controls > *:last-child {
  margin: 0px 16px;
}
.play-container {
  grid-row: 1;
  grid-column: 3;
  self-align: center;
  position: relative;
  display: flex;
  justify-content: center;

  .circular-progress {
    width: 120px;
    height: 120px;
    /* margin-left: 16px; */
  }
  .button.play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.transport.play {
  width: 64px;
  height: 64px;
}
.transport.pause {
  width: 64px;
  height: 64px;
}
.transport .icon {
  opacity: 0.85;
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
}
.transport:hover .icon {
  opacity: 1;
}
.transport.disabled {
  opacity: 0.4;
  pointer-events: none;
  cursor: default;
}
.transport.play .playIcon {
  visibility: visible;
}
.transport.play.active .playIcon {
  visibility: hidden;
}
.transport.play .pauseIcon {
  visibility: hidden;
}
.transport.play.active .pauseIcon {
  visibility: visible;
}
.transport.play {
  margin: auto;
}
.play-chooser {
  grid-row: 1;
  grid-column: 4 / span 2;
  justify-content: center;
}
.howfeeling-container {
  grid-area: howfeel;
  @include shadow;
  @include rounded;
  @include learn-island-inset;
}
.howfeeling-container {
  grid-area: usercontrol;
  @include shadow;
  @include rounded;
  @include learn-island-inset;
}
.revisitskip-container {
  grid-area: usercontrol;
  @include shadow;
  @include rounded;
  @include learn-island-inset;
}
.button.feedback {
  grid-column: 3;
}
.controls-container {
  grid-area: controls;
}

@media (orientation: portrait) {
  @media only screen and (max-width: 600px) {
    .transport-controls {
      display: flex;
      gap: 16px;
      .play-chooser {
        flex: 1;
      }
    }
    .transport-controls > *:first-child {
      margin-left: 16px;
    }
    .transport-controls > *:last-child {
      margin-right: 16px;
    }
  }
  @media only screen and (min-width: 600px) {
    .progress-widget-container {
      display: flex;
      justify-content: center;
    }
  }
}

@media (orientation: landscape) {
  @media only screen and (max-height: 800px) {
    .learn-container {
      max-width: 1000px;
    }
    .combo-container {
      grid-template-columns: 1fr auto;
      grid-template-rows: 1fr;
      grid-template-areas:
        "content progress";
      display: grid;
      min-height: 300px;
      @include learn-island-inset;
    }
    .text-mode-container, .video-container {
      grid-area: content;
      margin: 0 0;
      height: 100%;
      min-height: 300px;
    }
    .transport-controls {
      grid-area: progress;
      margin: 0 0;
      grid-template-columns: auto;
      grid-template-rows: 1fr;

      .play-container {
        grid-row: 1;
        grid-column: 1;
        self-align: center;
        .circular-progress {
          margin: auto 12px auto;
        }
      }
      .play-chooser {
        grid-row: 2;
        grid-column: 1;
        margin: initial;
        justify-content: center;
        align-items: center;
      }
    }
    .transport-controls > *:first-child {
      margin-left: 0;
    }
    .transport-controls > *:last-child {
      margin-right: 0;
    }
  }
}
