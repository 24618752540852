.checkbox-check {
  display: none;
}
.checkbox-check.checked {
  display: block;
}
.checkbox-box {
  display: inline-block;
  vertical-align: middle;
}
