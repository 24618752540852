.dropdown {
  position: relative;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.25);
}
.dropdown.open {
  z-index: 901;
}
.x-dropdown-toggle {
  position: relative;
  vertical-align: top;
  text-decoration: none;
  color: #222222;
  padding: 4px 8px;
  /* text-align: left; */
  margin-left: auto;
  margin-right: auto;
  white-space: nowrap;
}
.x-dropdown-toggle {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* display: inline-block; */
  cursor: pointer;
}
div[dir="ltr"].x-dropdown-toggle {
  padding-right: 40px;
}
div[dir="rtl"].x-dropdown-toggle {
  padding-left: 40px;
}
.x-dropdown-toggle:focus {
  outline: 0;
}
.w-icon-dropdown-toggle:before {
  content: "▼";
}
.w-icon-dropdown-toggle {
  position: absolute;
}
div[dir="ltr"] .w-icon-dropdown-toggle {
  right: 8px;
}
div[dir="rtl"] .w-icon-dropdown-toggle {
  left: 8px;
}
.dropdown-toggle {
  padding-top: 4px;
  padding-bottom: 4px;
}
.w-dropdown-list {
  opacity: 0;
  visibility: hidden;
  cursor: pointer;
  position: absolute;
  width: 100%;
  overflow: auto;
  margin-top: 1px; /* allow for the border around the box */
  background: #dddddd;
  -webkit-transition: opacity 400ms ease;
  transition: opacity 400ms ease;
}
.dropdown-back {
  display: none;
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0)
}
.dropdown-back.open+.w-dropdown-list {
  opacity: 1;
  visibility: visible;
  z-index: 10000;
}
.dropdown-back.open {
  display: block;
}
.dropdown-menu-entries {
  margin: 8px;
}
.w-dropdown-link:hover {
  background-color: rgba(10, 2, 2, 0.1);
}
.block-entry {
  margin-top: 4px;
  margin-bottom: 4px;
}
.dropdown-loading {
  font-style: italic;
  opacity: 50%;
}
