@use '../_styles' as *;

.groups-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.groups-list {
  margin: 16px 24px;
  @include standout;

  .list-header {
    font-size: 24px;
  }
  .list-elem {
    font-size: 22px;
  }
}
