.labelled-box {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  border-color: rgba(0, 0, 0, 0.25);
  padding: 10px;
}
.box-header {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 800;
  margin-bottom: 12px;
  user-select: none;
}
.box-header-label {
  flex-grow: 1;
}
