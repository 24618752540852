.add-dialog-title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 8px;
}
.suggestion {
  margin: 10px 0px;
}
.link-warning {
  font-style: italic;
}
.field-label.canolfan {
  margin-top: 32px;
}
.chooser-box.canolfan {
  margin-top: 12px;
  margin-bottom: 0px;
}
