@use '../_styles' as *;

.login-field-label {
  margin-bottom: 4px;
  font-weight: 500;
}
.login-button-label {
  text-align: center;
}
.forgot.button {
  align-self: flex-end;
  padding: 2px 6px 6px;
  color: $hilight-color;
}
.forgot-buttons {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-height: 400px) {
  .login-standout .standout-box {
    transform: scale(0.9);
  }
}
@media only screen and (max-height: 340px) {
  .login-standout .standout-box {
    transform: scale(0.8);
  }
}
