.button:not(.disabled) {
  transition: 0.2s all;
  cursor: pointer;
}
.button:active:not(.disabled) {
  transform: scale(0.95);
}
.button.wide:activenot(.disabled) {
  transform: scale(0.99, 0.95);
}
.button {
  user-select: none;
}
.button.inset-button {
  display: inline-block;
  padding: 7px 14px 6px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  -o-object-fit: contain;
  object-fit: contain;
}
.button.inset-button.inline-button {
    padding: 0px 8px;
}
.button.inset-button.disabled {
  opacity: 50%;
}
.button.inset-button.small {
  font-size: 12px;
  padding: 4px 7px;
}

.button.inset-button.submit-button {
  background-color: #b41324;
  color: #ffffff;
}
.button.inset-button.submit-button.disabled {
  border-color: rgba(0, 0, 0, 0.12);
  background-color: #d95b6a;
}
.button.color-button.submit-button {
  color: #b41324;
}
.button.color-button.submit-button.disabled {
  color: #d95b6a;
}

.button.submit-button.right {
  float: right;
}

.button.submit-button.middle {
  margin-left: 16px;
}
