.report-container {
  margin-bottom: 12px;
}
.download-button {
  float: right;
}
.download-button-label {
  text-decoration: none;
  color: black;
}
.groups-content {
  position: relative;
  margin: 16px 24px;
}
.group-report-header {
  font-size: 20px;
  font-weight: 700;
  user-select: none;
  margin-bottom: 12px;
}
.group-report-list-container {
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}
.group-report-list-container.body {
  margin-top: 16px;
}
.group-report-list-container.summary {
  margin-top: 8px;
}
.group-report-list-contents {
  margin: 8px;
}
.group-report-list {
  display: inline-block;
  font-size: 16px;
}
.group-report-list.grl-header {
  font-weight: 700;
  user-select: none;
}
.group-report-list.grl-column1 {
  width: 35%;
  text-overflow: ellipsis;
  overflow: hidden;
}
.group-report-list.grl-column2 {
  width: 50%;
  text-overflow: ellipsis;
  overflow: hidden;
}
.group-report-list.grl-column3 {
  width: 15%;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
}
.group-report-list.grl-column4 {
  text-align: center;
  width: 10%;
}
.group-report-list.grl-column5 {
  text-align: center;
  width: 10%;
}
.group-report-list-contents .horizontal-line {
  margin-top: 4px;
  margin-bottom: 4px;
}
.report-chooser-container {
  margin-top: 8px;
  margin-bottom: 6px;
}
.learner-email {
  margin-left: 4px;
  opacity: 65%;
}
