@use '../_styles' as *;

.tab-bar-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 1000px;
  margin: auto;
}
.tab-bar-content {
  height: 0;
  flex-grow: 1;
  overflow: auto;
}
.tab-bar-itself {
  display: flex;
  background-color: rgba(255,255,255,0.85);
  z-index: 1000;
  backdrop-filter: blur(3px);
  border-radius: 10px 10px 0px 0px;
}
.tab-bar-selector {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0px;
  .icon-box {
    display: flex;
    flex-direction: column;
    gap: 4px;
    cursor: pointer;
  }
  .icon {
    color: #444 !important;
    height: 32px;
  }
  .tab-name {
    color: #444;
    text-align: center;
  }
}
.tab-bar-selector.selected {
  .icon, .tab-name {
    color: $hilight-color !important;
  }
}

@media screen and (orientation:landscape)and (max-height: 500px)
{
  .tab-bar-container {
    flex-direction: row-reverse;
    max-width: 1200px;
  }
  .tab-bar-content {
    height: 100%;
  }
  .tab-bar-itself {
    flex-direction: column;
    border-radius: 0px;
  }
  .tab-bar-selector {
    margin: 0px 20px;
  }
}
