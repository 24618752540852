.learner-box.outlined {
  position: relative;
  box-sizing: border-box;
  padding: 6px 10px;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  border-color: rgba(0, 0, 0, 0.25);
  min-width: 150px;
}
.big-learner-chooser-title {
  font-size: 20px;
  font-weight: 350;
  text-align: center;
  margin-bottom: 12px;
}
.list-of-learners .list-body {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 10px;
}
.list-of-learners .list-elem {
  padding-bottom: 0px;
  padding-top: 0px;
}
.list-of-learners .icon {
  height: 22px;
  vertical-align: middle;
}
.list-of-learners .learner-name {
  font-weight: 350;
  vertical-align: middle;
}
