.close-button {
  opacity: 0.3;
  color: #000;
  font-size: 24px;
  font-weight: 700;
  padding-left: 4px;
  padding-right: 4px;
  cursor: pointer;
  user-select: none;
}
.close-button-upper-right {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 8px;
  margin-right: 8px;
}
