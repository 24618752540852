$hilight-color: #d32c2c;
$disabled-hilight-color: #d32c2c80;
$body-weight: 350;
$header-label-font-size: 18px;
$header-label-weight: 500;
$banner-font-size: 20px;
$banner-weight: 600;
$bottom-content-margin: 20px;

$large-box-radius: 12px;

@mixin hv-centered {
  display: flex;
  justify-content: center;
}

@mixin shadow {
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
}

@mixin rounded {
  border-radius: $large-box-radius;
}

$learn-island-offset: 40px;
@mixin learn-island-inset {
  margin: 0px $learn-island-offset;
}

@mixin standout {
  @include shadow;
  @include rounded;
  padding: 2rem;
}
