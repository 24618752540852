.tab-button-group {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 18px;
  -o-object-fit: contain;
  object-fit: contain;
}
.button.tab-button {
  -webkit-transition: color, background-color 200ms ease;
  transition: color, background-color 200ms ease;
}
.button.tab-button.left {
  border-radius: 6px 0px 0px 6px;
}
.button.tab-button.right {
  border-radius: 0px 6px 6px 0px;
}
.button.tab-button.middle {
  border-radius: 0px;
}
.button.tab-button.selected {
  background-color: #b41324;
  color: #ffffff;
}
