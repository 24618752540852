/*
.list {
  visibility: hidden;
  opacity: 0;
  height: 0px;
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
}
.list.visible {
  opacity: 1;
  visibility: visible;
  height: 100%;
} */
.list-header {
  font-size: 20px;
  font-weight: 800;
  margin-bottom: 12px;
  user-select: none;
}
.list-body {
  height: 100%;
  overflow: auto;
}
.list-elem {
  font-size: 16px;
  min-height: 16px;
  padding-top: 3px;
  padding-bottom: 3px;
  cursor: pointer;
  -webkit-transition: background-color 200ms ease;
  transition: background-color 200ms ease;
}
.list-elem.deemphasized {
  font-style: italic;
  color: rgba(0, 25, 0, 0.6);
}
.list-elem {
  transition: background-color 0.5s ease;
}
.list-elem:hover {
  background-color: rgba(10, 2, 2, 0.1);
}
.list-elem-detailed {
  background-color: rgba(10, 2, 2, 0.2);
  border-radius: 4px;
  padding: 4px 0px 8px 8px;
}
.list-elem-detail-box {
  margin-left: 12px;
}
.list.hide-icon .icon {
  opacity: 0;
  visibility: hidden;
  transition: opacity 400ms ease;
  padding-right: 4px;
}
.list-elem:hover .icon {
  opacity: 1;
  visibility: visible;
}
