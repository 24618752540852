@use '../_styles' as *;

.nav-panel-outer {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.nav-back-button-row {
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 12px;
  color: $hilight-color;
}
.nav-back-button {
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  user-select: none;
}
.navpanel-content {
  flex-grow: 1;
  min-height: 0;
  margin: 16px 24px;
}
