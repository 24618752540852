.class-elem {
  flex-grow: 1;
}
.play-icon.class-play-icon {
  width: 13px;
  margin-top: 2px;
  margin-right: 4px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 500ms ease;
}
.tiwtor-invite-row:hover .class-play-icon {
  opacity: 1;
  visibility: visible;
}
.year-level .list-body {
  margin-top: 4px;
  margin-left: 20px;
}
.year-box .lwn-label {
  font-weight: 500;
}
.all-class-box .lwn-label {
  font-weight: 350;
}
.list-elem.dragging {
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  border-color: rgba(0, 0, 0, 0.25);
}
.all-classes-list {
  overflow-y: auto;
}
.year-level .list-elem.acl-selected {
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  border-color: rgba(0,0,0,0.25);
  padding: 6px;
}
