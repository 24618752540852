@use './_styles' as *;

.suggestion {
  resize: none;
  padding: 10px;
  width: calc(100% - 22px);
}
.suggestion-box {
  margin: 20px 20px;
}
