.welcome-container {
  text-align: center;
  margin-top: 20px;
}
.welcome-top-banner {
  font-size: 28px;
  font-weight: 500;
}
.welcome-top-body {
  margin-top: 20px;
  font-size: 18px;
}
.ispeak-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 28px;
  top: 100%;
}
.ispeak-body .button {
  box-shadow: 4px 4px 10px 3px rgba(0, 0, 0, 0.25);
}
.ispeak-body .button:active {
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.25);
  transform: scale(0.98)
}
.ispeak {
  text-align: center;
  font-size: 22px;
}

