@use '../_styles' as *;

.standout-container {
  height: 100%;
  @include hv-centered;
}
.standout-box {
  align-self: center;
  width: 75vw;
  max-width: 500px;
  /* transform: translatey(-30%); an experiment */
  @include standout;
}
.standout-box .ssi-logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
}
