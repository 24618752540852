.welcome-box {
  display: flex;
  /* flex-direction: column;
  align-items: center; */
  justify-content: center;
}
.welcome-text {
  max-width: 600px;
}
.welcome-top {
  /* margin-top: 12px; */
  font-size: 28px;
  text-align: center;
}
.welcome-body {
  margin-top: 12px;
  font-size: 17px;
  margin-bottom: 12px;
}

@media only screen and (max-height: 500px) {
  .landing-standout .standout-box {
    transform: scale(0.85);
  }
}
@media only screen and (max-height: 400px) {
  .landing-standout .standout-box {
    transform: scale(0.75);
  }
}
@media only screen and (max-height: 340px) {
  .landing-standout .standout-box {
    transform: scale(0.65);
  }
}
