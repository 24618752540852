.inline-video-container {
  display: flex;
  margin-top: 1px;
  height: calc(100% - 2px);
}
.inline-video-box {
  margin: auto;
  height: 100%;
}
.inline-video-overlay-rel {
  position: relative;
  height: 100%;
}
.inline-video {
  height: 100%;
  max-width: 100%;
}
/*
.inline-video {
  width: 400px;
}
@media screen and (max-width: 400px) {
  .inline-video-container {
    display: block;
  }
  .inline-video {
    width: 100%;
  }
}
 */
.inline-banner {
  color: white;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
}
.inline-instructions {
  color: white;
  font-size: 18px;
  font-stye: italic;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px;
}
