.person-list-box {
  /* box-sizing: border-box; */
}
.person-list-box .list {
  /*
  flex-grow: 1; */
  min-height: 0px;
  height: 100%;
}
.person-list-box .list-body {
  /* height: 100%; */
}
.person-row {
  display: flex;
  align-items: center;
  gap: 6px;
}
.person-list .list-elem {
  transition: all 200ms;
}
.person-list .list-elem.pl-selected {
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  border-color: rgba(0,0,0,0.25);
  padding: 6px;
}
.person-name, .person-email {
  flex: 1 1 0;
  overflow-x: hidden;
  text-overflow: ellipsis;
  max-width: 50vw;
}
.invite-person-email {
  flex: 1 1 0;
}
.person-trash {
  padding-top: 1px;
}
